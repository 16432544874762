<template>
  <nav-bar>
    <div class="flex flex-right impoerExcel">
      <div>
        <el-upload
          :action="baseUrl"
          :headers="myheader"
          :on-success="handleSuccess"
          :file-list="fileList"
          :show-file-list="false"
        >
          <img src="../../assets/carts/daoru.png" alt="" />
          <span class="ml10">Cargar archivos</span>
        </el-upload>
      </div>
      <div class="mr40 text" @click="downloadExcel">Descarga de plantillas</div>
    </div>
    <template v-if="!total">
      <cart-empty :goods-list="goodsList"></cart-empty>
      <div class="listLoading" v-if="load">Espera un momento……</div>
    </template>
    <template>
      <cart-list
        :cartList="cartList"
        @reGetList="retGetCartList"
        :key="componentKey"
        @confirmBuy="confirmBuy"
        :shoplist="shopList"
      ></cart-list>
    </template>
  </nav-bar>
</template>

<script>// @ts-nocheck

var token = localStorage.getItem('token')
import navBar from '@/components/navBar/index.vue'
import cartEmpty from '@/views/carts/cart-empty'
import cartList from '@/views/carts/cart-list'
import { download, filterPicture } from '../../filters/index'

export default {
  components: {
    navBar,
    cartEmpty,
    cartList
  },

  data() {
    return {
      baseUrl: `${process.env.VUE_APP_REQUEST_URL}/order/uploadExcel`,
      myheader: { Authorization: 'Bearer ' + token },
      fileList: [],
      shopList: [],
      dolwnloadUrl:
        'main/xlsx/2021-10-28/10afc378-dbde-4d01-84b8-ad31ca334c09.xlsx',
      goodsList: [],
      cartList: [],
      total: false,
      query: {
        pageNo: 1,
        pageSize: 20,
        recommend: 1
      },

      loading: false,
      load: false,
      listLoaded: false,
      reload: true,
      componentKey: 0
    }
  },

  watch: {
    '$store.state.app.scrollTop'(val) {
      if (this.$route.path !== '/carts') return

      //滚动加载只对购物车为空时起作用
      if (this.total) return

      const clientHeight = document.documentElement.clientHeight
      if (
        document.body.scrollHeight - (val + clientHeight) < 1000 &&
        this.loading &&
        this.load
      ) {
        console.log('触发加载')
        ++this.query.pageNo
        this.getGoodsList()
      }
    }
  },

  created() {
    this.getCartList()
  },
  destroyed() {
     console.log(' 销毁页面:>> ', );
  },

  methods: {
    handleSuccess(file) {
      this.shopList = file.result
      this.confirmBuy()
      console.log('file--', file)
    },
    confirmBuy() {
      this.getCartList()
      console.log('到我了')
    },
    downloadExcel() {
      let url = filterPicture(this.dolwnloadUrl)
      this.$message.success('Dame un poco de tiempo')
      console.log('url', url)
      download(url)
    },
    retGetCartList() {
      this.query.pageNo = 1
      this.getCartList()
    },

    getCartList() {
      this.$axios.post('/cart/list', {}).then((res) => {
        this.cartList = res.data.result.map((item) => {
          item.selected = false
          item.addCount = item.nums
          item.storageSettingId = item.storageSettingId
          return item
        })
        console.log('购物车列表',this.cartList)
        this.total = this.cartList.length > 0 ? true : false

        // 利用组件key值，重新渲染<cart-list/>组件，不然有缓存
        this.componentKey += 1

        this.getGoodsList()
      })
    },

    getGoodsList() {
      // 如果有购物车数据，则不执行此方法
      if (this.total) {
        return
      }

      this.loading = false
      // this.$loading({text: 'Espera un momento', background: 'transparent'})
      this.$axios
        .post('/goods/auth/list', this.query)
        .then((res) => {
          this.loading = true
          res.data.result.rows.map((item) => {
            this.goodsList.push(item)
          })
          // this.$loading().close()
          console.log('推荐产品列表', this.goodsList)
          this.goodsTotal = res.data.result.total
          this.load = this.goodsTotal > this.goodsList.length
        })
        .catch((error) => {
          this.loading = true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.listLoading {
  margin-top: 50px;
  color: #409eff;
  text-align: center;
}
.impoerExcel {
  width: 1200px;
  margin: 20px auto;
  img {
    width: 20px;
    height: 20px;
  }
  .text {
    color: #8acf66;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-decoration: underline;
    cursor: pointer;
  }
  span {
    color: #282828;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
}
</style>

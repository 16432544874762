<template>
  <div class="full-page">
    <div class="empty-container">
      <img src="@/assets/public/empty.png" alt="" />
      <span>Nada todavía~</span>
    </div>
    <div class="title-bar">
      <div class="title">
        Te podría gustar
        <div class="bar-color"></div>
      </div>
    </div>
    <div v-loading="loading" element-loading-text="Espera un momento">
      <div class="goods-list">
        <product-card
          v-for="(item, index) in goodsList"
          :key="index"
          :goods="item"
          @toDetail="toDetail(item.id)"
          @getGoodsId="handelCart"
        ></product-card>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible" 
      width="1400px"
      @close="hiddenDialog"
    >
      <goods-carts v-if="cartsVisible" :goodsId="goodsId"></goods-carts>
    </el-dialog>
  </div>
</template>

<script>
import productCard from '@/components/product/index.vue'
import goodsCarts from '@/views/goods/index.vue'
export default {
  props: ['goodsList'],

  components: {
    productCard,
    goodsCarts
  },

  data() {
    return {
      loading: true,
      dialogVisible: false,
      goodsId: '',
      cartsVisible: false
    }
  },

  watch: {
    goodsList() {
      if (this.goodsList.length) this.loading = false
    }
  },

  methods: {
    toDetail(goodsId) {
      this.$router.push({
        path: '/goods',
        name: 'goods',
        params: { id: goodsId }
      })
    },

    handelCart($event) {
      this.goodsId = $event
      this.cartsVisible = true
      this.dialogVisible = true

      console.log(this.goodsId)
    },

    hiddenDialog() {
      this.dialogVisible = false
      this.cartsVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.full-page {
  width: 1200px;
  margin: 0 auto;

  .empty-container {
    width: 115px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    padding: 80px 0;
    img {
      width: 100%;
      display: block;
      margin-bottom: 15px;
    }
  }

  .title-bar {
    text-align: center;
    margin-bottom: 30px;
    .title {
      font-size: 28px;
      font-weight: bold;
      display: inline-block;
      .bar-color {
        width: 100%;
        height: 10px;
        background: #8acf66;
        margin-top: -15px;
      }
    }
  }

  .goods-list {
    min-height: 100px;
    > div {
      margin-top: 20px;
      margin-right: 20px;
    }
    :nth-child(4n + 4) {
      margin-right: 0;
    }
  }
}
</style>

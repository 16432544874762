<template>
  <div class="list-item" @click.stop="$emit('toDetail')">
    <div class="item-img cover-url_wrapper relative">
      <img
        :src="goods.coverUrl[0].url | filterPicture"
        alt=""
        v-if="goods.coverUrl[0].url"
      />
      <img :src="goods.coverUrl[0] | filterPicture" alt="" v-else />
      <!-- 库存不足 -->
      <div class="mask center" v-if="goods.storeCount === 0">
        Ha sido retirado
      </div>
      <!-- 下架 -->
      <div class="mask center" v-if="goods.putaway === 0">
        No disponible actualmente
      </div>
    </div>
    <div class="item-text">
      <div class="item-name">
        <span class="goods-sn">{{ goods.sn }}</span>
        <span>{{ goods.name }}</span>
      </div>
      <div class="flex">
        <el-rate v-model="starRating" disabled :colors="colors" />
      </div>
      <div>
        <div class="specs" v-if="cartInfo">
          <span
            v-for="(item, index) in JSON.parse(cartInfo.goodsSkuDetail.spec)"
            :key="index"
          >
            {{ (index === 0 ? '' : ' / ') + item.name }} : {{ item.value }}
          </span>
        </div>
        <div class="specs" v-else>
          <span v-for="(item, index) in goods.specs" :key="index">
            {{ (index === 0 ? '' : ' / ') + item.name }} : {{ item.value }}
          </span>
        </div>
        <div v-if="moreVisible" class="specs">
          Código de barras：{{ goods.skuBarCode }}
        </div>
        <div v-if="moreVisible" class="price">
          {{ goods.price | filterPrice }}
          <span>
            x {{ goods.deliveryNumber ? goods.deliveryNumber : goods.buyNums }}
          </span>
        </div>
      </div>
      <div class="specs" v-if="goods.purchaseLimit > 0">
        Límite de compra: {{ goods.purchaseLimit }}
      </div>
    </div>
  </div>
</template>

<script>
// @ts-nocheck

export default {
  props: ['moreVisible', 'cart-info', 'goodsInfo', 'detailInfo'],

  data() {
    return {
      starRating: 4,
      colors: ['#FCBBBB', '#FCBBBB', '#FCBBBB'],
      goods: {},
      addCount: 1,
      type: ''
    }
  },

  methods: {
    isJson(str) {
      try {
        if (typeof JSON.parse(str) == 'object') {
          return true
        }
      } catch (e) {}
      return false
    }
  },

  created() {
    if (this.cartInfo) {
      this.goods = { ...this.cartInfo }
      this.type = 'cartInfo'
      this.goods = this.cartInfo.goodsSkuDetail.goodsDetail
      this.addCount = this.cartInfo.nums
      this.goods.price = this.cartInfo.goodsSkuDetail.price
      this.goods.storeCount = this.cartInfo.goodsSkuDetail.storeCount
      this.goods.coverUrl = this.isJson(this.goods.coverUrl)
        ? JSON.parse(this.goods.coverUrl)
        : [this.goods.coverUrl]
      console.log('this.goods', this.goods)
      console.log('this.cartInfo', this.cartInfo)
      return
    }

    if (this.detailInfo) {
      this.goods = { ...this.goodsInfo }
      this.goods.sn = this.goodsInfo.goodsSn
      this.goods.name = this.goodsInfo.goodsName
      this.goods.price = this.goodsInfo.goodsPrice
      this.goods.coverUrl = this.isJson(this.goodsInfo.goodsCoverUrl)
        ? JSON.parse(this.goodsInfo.goodsCoverUrl)
        : [this.goodsInfo.goodsCoverUrl]

      console.log('this.detailInfo', this.goods)
      console.log('this.detailInfo-----', this.goodsInfo)

      return
    }

    this.goods = this.goodsInfo
    this.goods.coverUrl = this.isJson(this.goods.coverUrl)
      ? JSON.parse(this.goods.coverUrl)
      : [this.goods.coverUrl]

    console.log('goods', this.goods)
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  display: flex;
  cursor: pointer;

  .cover-url_wrapper {
    .mask {
      position: absolute;
      top: 34px;
      left: 10px;
      width: 119px;
      height: 60px;
      color: #fff;
      font-family: var(--font-family);
      font-size: var(--fs-16);
      font-weight: normal;
      line-height: 19px;
      background: rgba(204, 204, 204, 0.8);
      border-radius: 8px 8px 8px 8px;

      backdrop-filter: blur(6px); //毛玻璃属性
    }
  }
  .item-img {
    overflow: hidden;
    width: 140px;
    margin-right: 22px;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    border-radius: 8px;
    img {
      display: block;
      width: 100%;
      height: 140px;

      object-fit: cover;
    }
  }
  .item-text {
    position: relative;
    .item-name {
      overflow: hidden;
      max-width: 260px;
      padding: 8px 0;
      color: #000;
      font-size: 14px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      .goods-sn {
        display: inline-block;
        padding: 0 5px;
        margin-right: 10px;
        color: #fff;
        background: #fcbbbb;
        border-radius: 6px;
      }
    }
    .specs {
      margin: 10px 0;
      color: #979797;
      font-size: 16px;
      text-align: initial;
    }
    .price {
      color: #d43a57;
      font-size: 20px;
      span {
        color: #979797;
        font-size: 16px;
      }
    }
  }
}
</style>

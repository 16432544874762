<template>
  <div class="carts">
    <!-- <div>
     <div class="address flex">
       <img class="mb10" src="../../assets/public/position.png" alt="">
       <div class="text ml10 ">Posición actual ></div>
     </div>
   </div> -->

    <div v-if="cartList.length">
      <div class="title-bar">
        <div class="goods">Productos Básicos</div>
        <div>Precio unitario</div>
        <div>Cantidad</div>
        <div>Subtotal</div>
        <div>Operaciones</div>
      </div>
      <div class="cartList">
        <div class="cartItem" v-for="(item, index) in cartList" :key="index">
          <div class="select-icon" @click="selectGoods(item)">
            <div>
              <img
                v-if="item.selected"
                src="@/assets/carts/checkActive.png"
                alt=""
              />
              <img v-else src="@/assets/carts/check.png" alt="" />
            </div>
          </div>
          <div class="goods">
            <card-horizontal :cart-info="item"></card-horizontal>
          </div>
          <div class="goods-price">
            COP {{ item.goodsSkuDetail.price | filterPrice }}
          </div>
          <div>
            <el-input-number
              v-model="item.addCount"
              @change="handleChange($event, item)"
              :min="0"
              :max="
                item.goodsSkuDetail.goodsDetail.currentAvailableBuyNum ||
                item.goodsSkuDetail.storeCount
              "
              size="small"
              :disabled="
                item.goodsSkuDetail.goodsDetail.storeCount === 0 ||
                item.goodsSkuDetail.goodsDetail.putaway === 0
              "
            ></el-input-number>
            <div
              v-if="item.goodsSkuDetail.storeCount <= 0"
              class="storeCountTips"
            >
              Existencias insuficientes
            </div>
          </div>
          <div class="goods-price">
            COP {{ (item.goodsSkuDetail.price * item.nums) | filterPrice }}
          </div>
          <div class="delete-handle" @click="delSingleCart(item, index)">
            <img src="@/assets/carts/delete.png" alt="" />
            <span>Borrar</span>
          </div>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="bar-left">
          <div @click="reverseAllSelected">
            <img
              v-if="allSelected"
              src="@/assets/carts/checkActive.png"
              alt=""
            />
            <img v-else src="@/assets/carts/check.png" alt="" />
            <span>Selección completa</span>
          </div>
          <div @click="delCarts">
            <img src="@/assets/carts/delete.png" alt="" />
            <span>Borrar</span>
          </div>
        </div>
        <div class="bar-right">
          <div class="total-price">
            Total：COP {{ totalPrice | filterPrice }}
          </div>
          <el-button
            type="success"
            class="total-nums"
            :disabled="!active"
            @click="confirmBuy"
          >
            Comprar ( {{ selectedCount }} )
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ts-nocheck

var token = localStorage.getItem('token')
console.log('token', token)
import cardHorizontal from '@/components/product/card-horizontal.vue'
import { download, filterPicture } from '../../filters/index'
export default {
  props: {
    cartList: {
      type: Array
    },
    shoplist: {
      type: Array
    }
  },

  components: {
    cardHorizontal
  },

  data() {
    return {
      num: 1,
      active: false,
      withCredentials: true,
      baseUrl:
        'https://calabazapets.com/pet_supplies_mall/restApi/order/uploadExcel',
      myheader: { Authorization: 'Bearer ' + token },
      fileList: [],
      //  shopList:'',
      dolwnloadUrl:
        'main/xlsx/2021-10-28/10afc378-dbde-4d01-84b8-ad31ca334c09.xlsx'
    }
  },

  computed: {
    allSelected() {
      let newList = []
      this.cartList.forEach((item, index) => {
        if (item.goodsSkuDetail.storeCount && item.goodsSkuDetail.goodsDetail.putaway === 1) {
          newList.push(item)
        }
      })
      const noSelectIndex = newList.findIndex((item) => !item.selected)

      return noSelectIndex === -1
    },

    selectedCount() {
      let count = 0

      this.cartList.map((item) => {
        if (item.selected) ++count
      })

      return count
    },

    totalPrice() {
      let price = 0
      // 如果没有被选中的商品，则一直默认为false
      this.active = false

      this.cartList.map((item) => {
        if (item.selected) {
          this.active = true
          price += item.goodsSkuDetail.price * item.nums
          console.log('totalPrice', price, item)
        }
      })

      return price
    }
  },
  activated() {
    this.shopList = ''
  },
  methods: {
    downloadExcel() {
      let url = filterPicture(this.dolwnloadUrl)
      this.$message.success('Dame un poco de tiempo')
      console.log('url', url)
      download(url)
    },
    handleSuccess(file) {
      this.shopList = file.result
      this.confirmBuy()
      console.log('file--', file)
    },

    handleChange($event, item) {
      console.log('evnet', $event, 'item', item)

      //实时购物车数 - 上回购物车数 = 差
      let differ = $event - item.nums
      console.log($event, item.nums)

      const {
        goodsSkuDetail: { storeCount }
      } = item

      console.log('storeCount :>> ', storeCount)

      if ($event >= storeCount) {
        this.$set(item, 'nums', $event)
      } else {
        //实时更新item.nums的数量，是为了满足+1和-1的逻辑
        this.$set(item, 'nums', $event)
      }

      let form = {
        nums: differ,
        goodsSkuId: item.goodsSkuId,
        storageSettingId: item.storageSettingId
      }
      this.$axios.post('/cart/updateCart', form).then((res) => {
        const {
          data: { code }
        } = res
        if (code === 200) {
          if ($event === 0) {
            this.cartList.forEach((shop, index) => {
              if (shop.goodsSkuId === item.goodsSkuId) {
                this.$nextTick(() => {
                  this.cartList.splice(index, 1)
                })
              }
            })
          }
        }
      })
    },

    //单选
    selectGoods(row) {
      console.log('row :>> ', row)
      const {
        goodsSkuDetail: { storeCount, goodsDetail }
      } = row
      if (storeCount === 0) {
        this.$message.error('Sin stock')
        return
      }
      if (!goodsDetail.putaway) {
        this.$message.error('Ha sido retirado de')
        return
      }
      row.selected = !row.selected
    },

    // 多选
    reverseAllSelected() {
      const reverseSelected = !this.allSelected
      this.cartList.map((item) => {
        const { storeCount, goodsDetail } = item.goodsSkuDetail
        if (storeCount && goodsDetail.putaway) {
          item.selected = reverseSelected
        }
        return item
      })
    },

    confirmBuy() {
      this.$loading({ text: 'Espera un momento' })
      let selectedSkus = []
      if (this.shopList) {
        console.log('导入的商品')
        this.shopList.map((item) => {
          let data = {
            goodsSkuId: item.goodsSkuId,
            num: item.nums,
            storageSettingId: item.storageSettingId
          }
          selectedSkus.push(data)
        })
      } else {
        selectedSkus = []
        this.cartList.map((item) => {
          // 排除库存不足以及下架商品
          if (
            item.selected &&
            item.goodsSkuDetail.goodsDetail.storeCount !== 0 &&
            item.goodsSkuDetail.goodsDetail.putaway !== 0
          )
            selectedSkus.push({
              goodsSkuId: item.goodsSkuId,
              num: item.addCount,
              storageSettingId: item.storageSettingId
            })
        })
      }

      console.log('商品列表', selectedSkus)
      if (!selectedSkus.length) {
        this.$loading().close()
        this.$message.error('Por favor, elija comprar un producto válido')
        return
      }
      this.$emit('confirmBuy')

      setTimeout(() => {
        this.$loading().close()
        this.$router.push({
          path: '/create-order',
          name: 'create-order',
          params: { id: 1, skus: selectedSkus }
        })
      }, 300)
    },

    // 删除购物车
    delCarts() {
      let skuId = ''
      let skuIdlist = []
      let moreSkuId = false

      // 找出被选中的商品skuid
      this.cartList.map((item) => {
        if (item.selected) {
          const params = {
            goodsSkuId: item.goodsSkuId,
            storageSettingId: item.storageSettingId
          }
          skuIdlist.push(params)
        }
      })

      if (!skuIdlist.length) {
        this.$message.warning('Por favor, seleccione el producto')
        return
      }

      console.log(skuIdlist)
      this.$confirm('¿Está seguro de eliminar el Carrito de compras?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        confirmButtonColor: '#8ACF66'
      }).then((res) => {
        skuIdlist.length === 1 ? (skuId = skuIdlist[0]) : (moreSkuId = true)

        let temp = moreSkuId
          ? { goodsSkuIdSettingMapList: skuIdlist }
          : { goodsSkuIdSettingMapList: skuId }

        console.log(temp)
        this.$loading({ text: 'Espera un momento' })
        this.$axios.post('/cart/removeCart', temp).then((res) => {
          this.$emit('reGetList')
          this.$loading().close()
        })
      })
    },

    //删除单个商品
    delSingleCart(e, i) {
      console.log('删除单个商品', e, i)
      this.$confirm('¿Está seguro de eliminar el Carrito de compras?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        confirmButtonColor: '#8ACF66'
      }).then((res) => {
        this.$loading({ text: 'Espera un momento' })
        let goodsSkuIdSettingMapList = [
          {
            goodsSkuId: e.goodsSkuId,
            storageSettingId: e.storageSettingId
          }
        ]
        this.$axios
          .post('/cart/removeCart', { goodsSkuIdSettingMapList })
          .then((res) => {
            this.$emit('reGetList')
            this.$loading().close()
          })
      })
    }
  },

  watch: {
    cartList: {
      handler(val) {
        if (!val.length) {
          this.$emit('reGetList')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  border-bottom: solid 1px #e7e7e7;
  img {
    width: 16px;
  }
  .text {
    color: #606060;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
}
.impoerExcel {
  img {
    width: 20px;
    height: 20px;
  }
  .text {
    color: #8acf66;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-decoration: underline;
    cursor: pointer;
  }
  span {
    color: #282828;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
}
.carts {
  width: 1200px;
  margin: 20px auto;
  .title-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    color: #979797;
    font-size: 18px;
    text-align: center;
    border-bottom: 1px solid #e7e7e7;
    > div {
      flex: 1;
      text-align: center;
      &.goods {
        flex: 3;
        margin-left: 38px;
        text-align: left;
      }
    }
  }

  .cartList {
    .cartItem {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      font-size: 18px;
      text-align: center;
      border-bottom: 1px solid #e7e7e7;
      > div {
        flex: 1;
        &.goods {
          flex: 3;
        }
        &.goods-price {
          color: #d43a57;
          font-size: 20px;
        }
        &.delete-handle {
          color: #000;
          font-size: 18px;
          cursor: pointer;
          img {
            width: 24px;
            margin-right: 10px;
            vertical-align: middle;
          }
          span {
            vertical-align: middle;
          }
        }
      }

      .storeCountTips {
        margin-top: 5px;
        color: #d43a57;
        font-size: 16px;
      }

      .select-icon {
        flex: none;
        width: 18px;
        margin-right: 20px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
  }

  .bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    background: #f9fbf9;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    .bar-left {
      padding-left: 20px;
      > div {
        display: inline-block;
        margin-right: 100px;
        cursor: pointer;
        img {
          width: 18px;
          margin-right: 20px;
          vertical-align: middle;
        }
        span {
          color: #282828;
          font-size: 18px;
          vertical-align: middle;
        }
      }
    }
    .bar-right {
      > div {
        display: inline-block;
      }
      .total-price {
        margin-right: 10px;
        color: #d43a57;
        font-size: 20px;
      }
      .total-nums {
        height: 56px;
        color: #fff;
        font-size: 18px;
      }
    }
  }
}
</style>
